export const QWERTY = ["QWERTYUIOP", "ASDFGHJKL", "ZXCVBNM"];
export const LETTERS = QWERTY.join("").split("");
export const MIN_LENGTH = 3;
export const MAX_LENGTH = 20;
export const SHOW_CHARS = 10;
export const BONUS_LIMIT = 10;
export const MIN_WORDS = 1000;
export const MAX_WORDS = 10000;
export const WORDS_GOAL = 20;
export const LEVEL_NAMES = ["PERCEPTIVE", "SPECIALIST", "PERFECTIONIST"];
